<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        The graph shown below is the hypothetical heating curve showing the temperature of
        <stemble-latex content="$\textbf{50.00 g}$" />
        of a substance (with a molar mass of
        <number-value :value="molarMass" unit="\text{g/mol}" />) versus the amount of heat energy
        added to said substance.
      </p>

      <p class="mb-3 text-center">
        <v-img class="d-inline-block" :src="imageName" width="450px" />
      </p>

      <p class="mb-0">
        a) Which line segment(s) correspond to the
        <stemble-latex :content="`$\\textbf{${phaseA}}$`" />
        phase of the substance?
      </p>

      <v-simple-table class="mb-4 pl-7">
        <tbody>
          <tr>
            <td v-for="segmentOption in segmentOptions" :key="segmentOption">
              <v-checkbox v-model="inputs.input1" :value="segmentOption" :label="segmentOption">
                <template #label>
                  <stemble-latex :content="`$\\text{${segmentOption}}$`" />
                </template>
              </v-checkbox>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-0">b) Which line segment(s) correspond to phase changes?</p>

      <v-simple-table class="mb-4 pl-7">
        <tbody>
          <tr>
            <td
              v-for="segmentOption in segmentOptions"
              :key="segmentOption"
              style="text-align: center"
            >
              <v-checkbox v-model="inputs.input2" :value="segmentOption" :label="segmentOption">
                <template #label>
                  <stemble-latex :content="`$\\text{${segmentOption}}$`" />
                </template>
              </v-checkbox>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2">
        c) At what temperature does the substance
        <stemble-latex :content="`$\\textbf{${phaseChangeC}}$`" />
        ?
      </p>
      <calculation-input
        v-model="inputs.input3"
        class="mb-6"
        prepend-text="$\text{T:}$"
        append-text="$^\circ\text{C}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        d) Approximate the heat required (in kJ) to heat 50.0 g of the substance in the liquid phase
        from the melting point to the boiling point if the specific heat capacity of the liquid
        phase is equal to
        <stemble-latex :content="molarHeatCapacityLiquid" />
        <stemble-latex content="$\text{kJ g}^{-1}\,^\circ\text{C}^{-1}$" />. Express your answer to
        3 significant figures.
      </p>
      <calculation-input
        v-model="inputs.input4"
        class="mb-6"
        prepend-text="$\text{q:}$"
        append-text="$\text{kJ}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        e) If the molar enthalpy of fusion of the substance is
        <number-value :value="molarEnthalpyFusion" />
        <stemble-latex content="$\text{kJ mol}^{-1}$" />, determine the amount of heat required to
        melt the 50.0 g sample. Express your answer to 3 significant figures.
      </p>
      <calculation-input
        v-model="inputs.input5"
        class="mb-4"
        prepend-text="$\text{q}_{\text{fus}}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question252',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
        input2: [],
        input3: null,
        input4: null,
        input5: null,
      },
      options: [
        {expression: '$\\text{A}$', value: 'A'},
        {expression: '$\\text{B}$', value: 'B'},
        {expression: '$\\text{C}$', value: 'C'},
        {expression: '$\\text{D}$', value: 'D'},
        {expression: '$\\text{E}$', value: 'E'},
      ],
    };
  },

  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content.value;
    },
    segmentOptions() {
      return ['A', 'B', 'C', 'D', 'E'];
    },
    imageName() {
      return `/img/assignments/heatingProfile${this.versionNumber}b.jpg`;
    },
    versionData() {
      if (this.versionNumber === 1) {
        return {
          phaseA: 'solid',
          phaseChangeC: 'melt',
          molarHeatCapacityLiquid: '$0.364$',
        };
      } else if (this.versionNumber === 2) {
        return {
          phaseA: 'solid',
          phaseChangeC: 'melt',
          molarHeatCapacityLiquid: '$0.519$',
        };
      } else if (this.versionNumber === 3) {
        return {
          phaseA: 'liquid',
          phaseChangeC: 'freeze',
          molarHeatCapacityLiquid: '$1.68$',
        };
      } else if (this.versionNumber === 4) {
        return {
          phaseA: 'liquid',
          phaseChangeC: 'freeze',
          molarHeatCapacityLiquid: '$2.25$',
        };
      } else if (this.versionNumber === 5) {
        return {
          phaseA: 'gas',
          phaseChangeC: 'boil',
          molarHeatCapacityLiquid: '$0.485$',
        };
      } else if (this.versionNumber === 6) {
        return {
          phaseA: 'gas',
          phaseChangeC: 'boil',
          molarHeatCapacityLiquid: '$3.45$',
        };
      } else {
        return {
          phaseA: 'ERROR',
          phaseChangeC: 'ERROR',
          molarHeatCapacityLiquid: 'ERROR',
        };
      }
    },
    phaseA() {
      return this.versionData.phaseA;
    },
    phaseChangeC() {
      return this.versionData.phaseChangeC;
    },
    molarHeatCapacityLiquid() {
      return this.versionData.molarHeatCapacityLiquid;
    },
    molarEnthalpyFusion() {
      return this.taskState.getValueBySymbol('molarEnthalpyFusion').content;
    },
    molarMass() {
      return this.taskState.getValueBySymbol('molarMass').content;
    },
  },
};
</script>
